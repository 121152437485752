import React, {useState} from 'react'
import '../App.scss';
import './Login.scss';
import {Link} from 'react-router-dom';

export default function Login(props) {
    const [state, setState] = useState({
        email: "",
        password: "",
        type: 1,
        error: false,
        errorMessage: "",
        emailExists: true,
        incorrectPassword: false
    });

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: state.email,
                password: state.password,
                type: state.type
            })
        })

        let json = await response.json()

        if (response.status === 200) {
            props.history.push('/profile');
        } else if (response.status === 400) {
            setState({
                ...state,
                error: true,
                errorMessage: json.errorMessage,
                emailExists: json.emailExists,
                incorrectPassword: json.incorrectPassword
            })
        }
    }

    return (
        <div className="login-container container-fluid ">
            <div className="login">
                <h1>Log In</h1>
                <p className="error-message" hidden={!state.error}>Error: {state.errorMessage}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="login-email">Email Address</label>
                        <input type="email" className={state.emailExists ? "form-control" : "form-control is-invalid"}
                               id="login-email"
                               placeholder="Enter Email Address"
                               value={state.email}
                               onChange={e => setState(state => ({
                                   ...state,
                                   email: e.target.value,
                                   emailExists: true
                               }))}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="login-password">Password</label>
                        <input type="password"
                               className={state.incorrectPassword ? "form-control is-invalid" : "form-control"}
                               id="login-password"
                               placeholder="Password"
                               value={state.password}
                               onChange={e => setState(state => ({
                                   ...state,
                                   password: e.target.value,
                                   incorrectPassword: false
                               }))}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="login-type">Type</label>
                        <select id="login-type" value={state.type} onChange={e => setState(state => ({
                            ...state,
                            type: e.target.value
                        }))} className="custom-select"
                                required={true}>
                            <option value="1">Student</option>
                            <option value="2">Teacher</option>
                        </select>
                    </div>
                    <div className="check-submit">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="login-check"/>
                            <label className="form-check-label" htmlFor="login-check">Remember me</label>
                        </div>
                        <button type="submit" className="btn">Login</button>
                    </div>
                </form>
                <Link to="/signup" className="">Dont have an account? Sign Up</Link>
            </div>
        </div>

    )
}
