import React, {useEffect, useState} from 'react'
import '../App.scss';
import './Profile.scss';
import Schedule from './Schedule';
import Personal from './Personal';
import {Link} from 'react-router-dom';

export default function Profile() {
    const [state, setState] = useState({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        status: 0,
        type: 0
    });

    function getStatus() {
        switch (state.status) {
            case 0:
                return "Active " + (state.type === 2 ? "Teacher" : "Student")
            case 1:
                return "Inactive " + (state.type === 2 ? "Teacher" : "Student")
            default:
                return "Unknown"
        }
    }

    useEffect(() => {
        fetch('/api/profile').then(async res => {
            const json = await res.json()

            setState(state => ({
                ...state,
                id: json.id,
                firstName: json.firstName,
                lastName: json.lastName,
                email: json.email,
                phoneNumber: json.phoneNumber,
                address: json.address,
                status: parseInt(json.status),
                type: parseInt(json.type)
            }))
        })
    }, [])


    function personalClick() {
        document.getElementById("personal-button").classList.add("active-filter");
        document.getElementById("schedule-button").classList.remove("active-filter");

        document.getElementById("personal").classList.remove("hidden");
        document.getElementById("schedule").classList.add("hidden");
    }

    function scheduleClick() {
        document.getElementById("personal-button").classList.remove("active-filter");
        document.getElementById("schedule-button").classList.add("active-filter");

        document.getElementById("personal").classList.add("hidden");
        document.getElementById("schedule").classList.remove("hidden");
    }

    return (
        <div className="profile container-fluid">
            <div className="top-nav-container row">
                <div className="top-nav">
                    <h1>Team 7 High School Scheduler</h1>
                    <div>
                        <i className="far fa-bell fa-2x"/>
                        <i className="fas fa-user fa-2x"/>
                        <Link to="/logout"><i className="fas fa-sign-out-alt fa-2x"/></Link>
                    </div>
                </div>
            </div>
            <div className="profile-content row">
                <div className="left col-3">
                    <div className="sidebar">
                        <div className="user">
                            <i className="fas fa-user fa-6x"/>
                            <h1>{state.firstName} {state.lastName}</h1>
                            <h2>{getStatus()} | University of Rochester</h2>
                        </div>
                        <div className="profile-links">
                            {/*<Link to="/summary">Summary</Link>*/}
                            {/* eslint-disable-next-line */}
                            <a className="disabled" href="#">Summary</a>
                            <Link to="/profile" className="active-link">Personal</Link>
                            {/* eslint-disable-next-line */}
                            <a className="disabled" href="#">Contact</a>
                            {/* eslint-disable-next-line */}
                            <a className="disabled" href="#">Financials</a>
                        </div>
                    </div>
                </div>

                <div className="right col-9">
                    <div className="profile-info">
                        <div className="info-buttons">
                            <button id="personal-button" className="active-filter" onClick={personalClick}
                                    type="button">Personal Info
                            </button>
                            <button id="schedule-button" className="" onClick={scheduleClick} type="button">Schedule
                            </button>
                        </div>
                        <div className="info-content">
                            <div id="personal" className="row">
                                <div className="col-6">
                                    <Personal
                                        header="ID"
                                        content={state.id}
                                        edit={false}
                                    />
                                </div>

                                <div className="col-6">
                                    <Personal
                                        header="Email"
                                        content={state.email}
                                        edit={false}
                                    />
                                </div>

                                <div className="col-6">
                                    <Personal
                                        header="First Name"
                                        content={state.firstName}
                                        edit={true}
                                    />
                                </div>

                                <div className="col-6">
                                    <Personal
                                        header="Last Name"
                                        content={state.lastName}
                                        edit={true}
                                    />
                                </div>

                                <div className="col-6">
                                    <Personal
                                        header="Phone Number"
                                        content={state.phoneNumber}
                                        edit={true}
                                    />
                                </div>

                                <div className="col-6">
                                    <Personal
                                        header="Address"
                                        content={state.address}
                                        edit={true}
                                    />
                                </div>
                            </div>

                            <div id="schedule" className="hidden">
                                <Schedule/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
