import React, {useEffect, useState} from 'react'
import '../App.scss';
import './Schedule.scss';
import {Link} from "react-router-dom";
import axios from "axios";

export default function Schedule() {
    const [state, setState] = useState({
        schedule: [],
        type: 1
    });

    useEffect(() => {
        axios.get('/api/schedule').then(json => {
            setState(state => ({
                ...state,
                schedule: json.data.schedule
            }))
        })

        axios.get('/api/profile').then(json => {
            setState(state => ({
                ...state,
                type: json.data.type
            }))
        })
    }, [])

    return (
        <div className="schedule-container">
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Course Name</th>
                    <th scope="col">Room Number</th>
                    <th scope="col">Instructor</th>
                </tr>
                </thead>
                <tbody>
                {state.schedule.map(classInfo => <tr key={classInfo.classID}>
                    <td>{classInfo.className}</td>
                    <td>{classInfo.roomNumber}</td>
                    <td>{classInfo.Teacher.firstName} {classInfo.Teacher.lastName}</td>
                </tr>)}
                </tbody>
            </table>

            <div className="edit-delete">
                {state.type === 1 ? null :
                    <Link to="create-department">
                        <button id="create-department" className="" type="button">Create Department</button>
                    </Link> }

                {state.type === 1 ? null :
                    <Link to="delete-department">
                        <button id="delete-department" className="" type="button">Delete Department</button>
                    </Link> }
            </div>

            <div className="edit-delete">
                {state.type === 1 ?
                    <Link to="join-class">
                        <button id="edit-schedule" className="" type="button">Join Class</button>
                    </Link> :
                    <Link to="create-class">
                        <button id="edit-schedule" className="" type="button">Create Class</button>
                    </Link>
                }
                {state.type === 1 ?
                    <Link to="leave-class">
                        <button id="delete-schedule" className="" type="button">Leave Class</button>
                    </Link> :
                    <Link to="delete-class">
                        <button id="delete-schedule" className="" type="button">Delete Class</button>
                    </Link>
                }
            </div>
        </div>
    )
}
