import React from 'react'
import '../App.scss';
import './Personal.scss';
import {Link} from "react-router-dom";

export default function Personal(props) {
    return (
        <div className="personal-container">
            <div className="personal-top">
                <h1>{props.header}</h1>
                <Link to="/edit"><i className="fas fa-edit" hidden={!props.edit}/></Link>
            </div>
            <div className="personal-content">
                <h1>{props.content}</h1>
            </div>
        </div>
    )
}
