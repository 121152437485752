import React, {useEffect, useState} from 'react'
import '../App.scss';
import './Edit.scss';

export default function Edit(props) {
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: ""
    });

    useEffect(() => {
        fetch('/api/profile').then(async res => {
            const json = await res.json()

            setState(state => ({
                ...state,
                firstName: json.firstName,
                lastName: json.lastName,
                email: json.email,
                phoneNumber: json.phoneNumber,
                address: json.address
            }))
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/updateProfile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                phoneNumber: state.phoneNumber,
                address: state.address,
            })
        })

        if (response.status === 200) {
            props.history.push('/profile');
        }
    }

    return (
        <div className="edit-container container-fluid">
            <div className="edit">
                <h1>Edit Profile</h1>
                <p className="error-message" hidden={!state.error}>Error: {state.errorMessage}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="edit-fname">First Name</label>
                        <input type="text" className="form-control" id="edit-fname" placeholder="Enter First Name"
                               value={state.firstName}
                               onChange={e => setState(state => ({
                                   ...state,
                                   firstName: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="edit-lname">Last Name</label>
                        <input type="text" className="form-control" id="edit-lname" placeholder="Enter Last Name"
                               value={state.lastName}
                               onChange={e => setState(state => ({
                                   ...state,
                                   lastName: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="edit-phone">Phone Number</label>
                        <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="form-control"
                               id="edit-phone" placeholder="123-456-7890"
                               value={state.phoneNumber}
                               onChange={e => setState(state => ({
                                   ...state,
                                   phoneNumber: e.target.value
                               }))}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="edit-address">Address</label>
                        <input type="text" className="form-control" id="edit-address" placeholder="Enter Address"
                               value={state.address}
                               onChange={e => setState(state => ({
                                   ...state,
                                   address: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="submit-button">
                        <button className="btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
