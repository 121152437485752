import React, {useEffect, useState} from 'react'
import '../App.scss';
import './Form.scss';

export default function DeleteDepartment(props) {
    const [state, setState] = useState({
        departments: [],
        departmentID: 0
    });

    useEffect(() => {
        fetch('/api/departments').then(async res => {
            const json = await res.json()

            setState(state => ({
                ...state,
                departments: json.departments
            }))
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/deleteDepartment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                departmentID: state.departmentID
            })
        })

        if (response.status === 200) {
            props.history.push('/profile');
        } else if (response.status === 401) {
            setState(state => ({
                ...state,
                error: true,
                errorMessage: "Insufficient Permissions (You're not logged on as a Teacher)"
            }))
        } else if (response.status === 500) {
            setState(state => ({
                ...state,
                error: true,
                errorMessage: "Internal Server Error"
            }))
        }
    }

    return (
        <div className="form-container container-fluid">
            <div className="form">
                <h1>Delete Department</h1>
                <p className="error-message" hidden={!state.error}>Error: {state.errorMessage}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="select-department">Select Department</label>
                        <select id="select-department" value={state.departmentID} onChange={e => setState(state => ({
                            ...state,
                            departmentID: e.target.value
                        }))} className="custom-select"
                                required={true}>
                            <option value="0">None Selected...</option>
                            {state.departments.map((department) => <option key={department.departmentID}
                                                                           value={department.departmentID}>{department.departmentName}</option>)}
                        </select>
                    </div>
                    <div className="submit-button">
                        <button className="btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
