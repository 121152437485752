import React from 'react';
import Login from './components/Login';
import Logout from './components/Logout';
import Signup from './components/Signup';
import Profile from './components/Profile';
import Edit from './components/Edit';
import Class from './components/Class';
import JoinClass from './components/JoinClass';
import LeaveClass from './components/LeaveClass';
import DeleteClass from './components/DeleteClass'
import Department from "./components/Department";
import DeleteDepartment from "./components/DeleteDepartment";
import IsAuthenticated from './components/IsAuthenticated';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
    return (
        <Router>
            <div className="app">
                <Switch>
                    <Route path="/" exact component={Login}/>
                    <Route path="/logout" exact component={Logout}/>
                    <Route path="/signup" exact component={Signup}/>
                    <Route path="/profile" exact component={IsAuthenticated(Profile)}/>
                    <Route path="/edit" exact component={IsAuthenticated(Edit)}/>
                    <Route path="/create-class" exact component={IsAuthenticated(Class)}/>
                    <Route path="/join-class" exact component={IsAuthenticated(JoinClass)}/>
                    <Route path="/leave-class" exact component={IsAuthenticated(LeaveClass)}/>
                    <Route path="/delete-class" exact component={IsAuthenticated(DeleteClass)}/>
                    <Route path="/create-department" exact component={IsAuthenticated(Department)}/>
                    <Route path="/delete-department" exact component={IsAuthenticated(DeleteDepartment)}/>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
