import React, { useState } from 'react'
import '../App.scss';
import './Form.scss';

export default function Department(props) {
    const [state, setState] = useState({
        departmentName: ""
    });

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/createDepartment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                departmentName: state.departmentName
            })
        })

        if (response.status === 200) {
            props.history.push('/profile');
        } else if (response.status === 401) {
            setState(state => ({
                ...state,
                error: true,
                errorMessage: "Insufficient Permissions (You're not logged on as a Teacher)"
            }))
        } else if (response.status === 500) {
            setState(state => ({
                ...state,
                error: true,
                errorMessage: "Internal Server Error"
            }))
        }
    }

    return (
        <div className="form-container container-fluid">
            <div className="form">
                <h1>Create Department</h1>
                <p className="error-message" hidden={!state.error}>Error: {state.errorMessage}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="departmentName">Department Name</label>
                        <input type="text" className="form-control" id="departmentName" placeholder="Enter Department Name"
                               value={state.departmentName}
                               onChange={e => setState(state => ({
                                   ...state,
                                   departmentName: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="submit-button">
                        <button className="btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
