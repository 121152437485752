import React, {useState} from 'react'
import '../App.scss';
import './Signup.scss';
import {Link} from 'react-router-dom';

export default function Signup(props) {
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        address: "",
        status: 1,
        password: "",
        error: false,
        emailTaken: false,
        errorMessage: ""
    });

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                phoneNumber: state.phoneNumber,
                address: state.address,
                status: state.status,
                password: state.password,
            })
        })

        let json = await response.json()

        if (response.status === 201) {
            props.history.push('/');
        } else if (response.status === 400) {
            setState({
                ...state,
                error: json.error,
                invalidStatus: false,
                errorMessage: json.errorMessage,
                emailTaken: json.emailTaken
            })
        }
    }

    return (
        <div className="signup-container container-fluid">
            <div className="signup">
                <h1>Sign Up</h1>
                <p className="error-message" hidden={!state.error}>Error: {state.errorMessage}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="signup-fname">First Name</label>
                        <input type="text" className="form-control" id="signup-fname" placeholder="Enter First Name"
                               value={state.firstName}
                               onChange={e => setState(state => ({
                                   ...state,
                                   firstName: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="signup-lname">Last Name</label>
                        <input type="text" className="form-control" id="signup-lname" placeholder="Enter Last Name"
                               value={state.lastName}
                               onChange={e => setState(state => ({
                                   ...state,
                                   lastName: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="signup-email">Email Address</label>
                        <input type="email" className={state.emailTaken ? "form-control is-invalid" : "form-control"}
                               id="signup-email" placeholder="Enter Email Address"
                               value={state.email}
                               onChange={e => setState(state => ({
                                   ...state,
                                   email: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="signup-phone">Phone Number</label>
                        <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="form-control"
                               id="signup-phone" placeholder="123-456-7890"
                               value={state.phoneNumber}
                               onChange={e => setState(state => ({
                                   ...state,
                                   phoneNumber: e.target.value
                               }))}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="signup-address">Address</label>
                        <input type="text" className="form-control" id="signup-address" placeholder="Enter Address"
                               value={state.address}
                               onChange={e => setState(state => ({
                                   ...state,
                                   address: e.target.value
                               }))} required={true}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="signup-status">Status</label>
                        <select id="signup-status" value={state.status} onChange={e => setState(state => ({
                            ...state,
                            status: e.target.value
                        }))} className="custom-select"
                                required={true}>
                            <option value="1">Active Student</option>
                            <option value="2">Inactive Student</option>
                            <option value="3">Active Teacher</option>
                            <option value="4">Inactive Teacher</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="signup-password">Password</label>
                        <input type="password" className="form-control" id="signup-password" placeholder="Password"
                               value={state.password}
                               onChange={e => setState(state => ({
                                   ...state,
                                   password: e.target.value
                               }))}
                               required={true}/>
                    </div>
                    <div className="signup-button">
                        <Link to="/" className="">Already have an account? Log In</Link>
                        <button className="btn">Signup</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
