import React, {useEffect, useState} from 'react'
import '../App.scss';
import './Form.scss';

export default function JoinClass(props) {
    const [state, setState] = useState({
        classes: [],
        classID: 0
    });

    useEffect(() => {
        fetch('/api/classes').then(async res => {
            const json = await res.json()

            setState(state => ({
                ...state,
                classes: json.classes
            }))
        })
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/joinClass', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                classID: state.classID
            })
        })

        if (response.status === 200) {
            props.history.push('/profile');
        } else if (response.status === 401) {
            setState(state => ({
                ...state,
                error: true,
                errorMessage: "Insufficient Permissions (You're not logged on as a Student)"
            }))
        } else if (response.status === 500) {
            setState(state => ({
                ...state,
                error: true,
                errorMessage: "Internal Server Error"
            }))
        }
    }

    return (
        <div className="form-container container-fluid">
            <div className="form">
                <h1>Create Class</h1>
                <p className="error-message" hidden={!state.error}>Error: {state.errorMessage}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="select-class">Select Class</label>
                        <select id="select-class" value={state.classID} onChange={e => setState(state => ({
                            ...state,
                            classID: e.target.value
                        }))} className="custom-select"
                                required={true}>
                            <option value="0">None Selected...</option>
                            {state.classes.map((_class) => <option key={_class.classID}
                                                                   value={_class.classID}>{_class.className} </option>)}
                        </select>
                    </div>
                    <div className="submit-button">
                        <button className="btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
